import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Header from '../Landing/Header'
import About from './About'
import PoliciesSection from './PoliciesSection'
import Footer from '../Landing/Footer'
import BookConsultationBanner from './Contact/BookConsultationBanner'

const PoliciesFinder: React.FC<RouteComponentProps<any>> = ({}) => {
  return (
    <div>
      <div className="w-screen flex flex-col bg-white mx-auto font-lato">
        <Header bgColor="bg-[#E4EEEC]" />
        <About />
        <PoliciesSection />
        <Footer />
      </div>
      <BookConsultationBanner />
    </div>
  )
}

export default PoliciesFinder
