import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { PolicyTab } from '@src/utils/policies-finder'
import { MultiSelect } from 'react-multi-select-component'
import { CSVLink } from 'react-csv'
import rawPolicies, { Policy } from './rawPolicies'
import rawStates from './rawStates'
import { slackAxiosInstance } from '@src/api/slackAxiosInstance'
import { useClickAway } from '@uidotdev/usehooks'
import { freeBasicEmailDomain } from '@src/utils/freeBasicEmailDomain'

const IndustryOptions = [
  // { label: 'Select all relevant industries', value: '', disabled: true, isGroupText: true },
  { label: 'Select all industries', value: 'all', isPickAll: true },
  { label: 'Technology', value: 'technology' },
  { label: 'Healthcare', value: 'healthcare' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Professional services', value: 'professional_services' },
  { label: 'Non-profit', value: 'non_profit' },
  { label: 'Government', value: 'government' },
  { label: 'Trucking', value: 'trucking' },
  { label: 'Construction', value: 'construction' },
  { label: 'Other', value: 'other' },
]

const policies: Policy[] = rawPolicies.filter((policy) => policy.region !== 'Federal')
const federalPolicies: Policy[] = rawPolicies.filter((policy) => policy.region === 'Federal')

const PoliciesTableDesktop = ({ policiesToShowOnUI }) => {
  return (
    <div className="policies-table pt-[20px]">
      {policiesToShowOnUI.length > 0 ? (
        <>
          {policiesToShowOnUI.map((policy, index) => {
            return (
              <div
                key={index}
                className="policy-card flex flex-row justify-between rounded-lg border-[0.73px] border-gray-6 pt-[29px] pb-[17px] pl-[33px] pr-[18px] my-2"
              >
                <div className="text-div max-w-[591px]">
                  <div className="card-title font-bold text-[16px] leading-[14px] text-green-4">{policy.label}</div>
                  <div className="card-description text-[13px] leading-[20px] text-gray-5 pt-[10px]">
                    {policy.description}
                  </div>
                </div>
                <div className="label-div max-w-[97px]">
                  <div className="w-[97px] rounded-full border-gray-1 border-[0.73px]">
                    <p className="text-center py-[5px] text-[11px] text-green-4">
                      {policy.regions.length > 1
                        ? `${policy.regions[0]}+${policy.regions.length - 1}`
                        : policy.regions[0]}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const PoliciesTableMobile = ({ policiesToShowOnUI }) => {
  return (
    <div className="policies-table sm:block hidden bg-white-1">
      {policiesToShowOnUI.length > 0 ? (
        <>
          {policiesToShowOnUI.map((policy, index) => {
            return (
              <div
                key={index}
                className="policy-card flex flex-row justify-between border-b-[0.73px] border-gray-6 pt-[29px] pb-[17px] mb-2 last:mb-0 last:border-b-0 "
              >
                <div className="text-div max-w-[591px]">
                  <div className="card-title font-bold text-[16px] leading-[14px] text-green-4">{policy.label}</div>
                  <div className="w-[97px] rounded-[13px] border-gray-1 border-[0.73px] mt-4">
                    <p className="text-center py-[5.5px] text-[11px] text-green-4">
                      {policy.regions.length > 1
                        ? `${policy.regions[0]}+${policy.regions.length - 1}`
                        : policy.regions[0]}
                    </p>
                  </div>
                  <div className="card-description text-[13px] leading-[20px] text-gray-5 pt-[10px]">
                    {policy.description}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

interface PolicyTabsProps {
  currentTab: PolicyTab
  setCurrentTab: (tab: PolicyTab) => void
  totalRequiredPolicies: number
  totalRecommendedPolicies: number
  selectedStates: { label: string; value: string }[]
  clickingGo: boolean
  policiesToShowOnUIRequired: Policy[]
  policiesToShowOnUIRecommended: Policy[]
  federalPoliciesMerged: Policy[]
}

const PolicyTabs = ({
  currentTab,
  setCurrentTab,
  totalRequiredPolicies,
  totalRecommendedPolicies,
  policiesToShowOnUIRequired,
  policiesToShowOnUIRecommended,
  clickingGo,
  selectedStates,
  federalPoliciesMerged,
}: PolicyTabsProps) => {
  const [requiredSelected, setRequiredSelected] = useState(false)
  const [recommendedSelected, setRecommendedSelected] = useState(false)

  return (
    <div className="tab-section grid grid-cols-2 sm:grid-cols-1 bg-[#F4F4F4]">
      <div
        onClick={() => {
          setCurrentTab(PolicyTab.REQUIRED)
          setRequiredSelected(!requiredSelected)
        }}
        className={`first-tab px-[22px] sm:px-[27px] py-[24px] flex flex-row sm:flex-row-reverse sm:justify-between gap-x-[21px] cursor-pointer sm:bg-white-1 ${currentTab === PolicyTab.REQUIRED && 'bg-white-1'
          }`}
      >
        <div
          className={`w-[79px] h-[62px] border-[0.73px] border-[#BBD1CD] rounded-md flex ${totalRequiredPolicies > 0 ? 'border' : 'border-dashed'
            }`}
        >
          <div
            className={`font-bold text-[36.5px] m-auto text-[#0E3A36] ${totalRequiredPolicies === 0 && 'text-[#0E3A3652]'
              }`}
          >
            {!clickingGo ? (totalRequiredPolicies) : 0}
          </div>
        </div>
        <div className="required-div py-[8px] flex flex-col justify-around">
          <div className="tab-label flex flex-row gap-x-[13px]">
            <TabLabel>Required</TabLabel>
            <img className="w-[13px] h-[10pxpx]" src="/images/policies-finder/ic_required.svg" alt="logo" />
          </div>
          <div className="tab-description">
            <TabDescription>Legally required for your company.</TabDescription>
          </div>
        </div>
      </div>

      {requiredSelected && (
        <div className="hidden sm:block overflow-scroll max-h-[400px]">
          {(policiesToShowOnUIRequired.length > 0 || (selectedStates.filter(state => state.value === 'federals').length === 1 && clickingGo === false)) && (
            <div className="px-[33px] bg-white-1">
              {
                ((selectedStates.filter(state => state.value === 'federals').length === 1 && clickingGo === false)) && (

                  <>
                    <div className="total-policies pb-6 bg-white border-b border-gray-6">
                      <p>
                        <span className="text-base text-green-4">
                          Federal policies
                        </span>
                        <span className="text-base text-green-4">{` (${federalPoliciesMerged.length})`}</span>
                      </p>
                    </div>
                    <PoliciesTableMobile policiesToShowOnUI={federalPoliciesMerged} />
                  </>
                )
              }
              {/* Mobile here */}
              <div className="total-policies py-6 border-b border-b-gray-6">
                <p>
                  <span className="text-base text-green-4">State specific policies</span>
                  <span className="text-base text-green-4">{` (${policiesToShowOnUIRequired.length})`}</span>
                </p>
              </div>
              {/* Mobile here */}
              <PoliciesTableMobile policiesToShowOnUI={policiesToShowOnUIRequired} />
            </div>
          )}
        </div>
      )}

      <div
        onClick={() => {
          setCurrentTab(PolicyTab.RECOMMENDED)
          setRecommendedSelected(!recommendedSelected)
        }}
        className={`second-tab sm:border-t sm:border-gray-2 second-tab px-[22px] sm:px-[27px] py-[24px] flex flex-row sm:flex-row-reverse sm:justify-between gap-x-[21px] cursor-pointer sm:bg-white-1 ${currentTab === PolicyTab.RECOMMENDED && 'bg-white-1 rounded-tl-sm'
          }`}
      >
        <div
          className={`w-[79px] h-[62px] border-[0.73px] border-[#BBD1CD] rounded-md flex ${totalRecommendedPolicies > 0 ? 'border' : 'border-dashed'
            }`}
        >
          <div
            className={`font-bold text-[36.5px] m-auto text-[#0E3A36] ${totalRecommendedPolicies === 0 && 'text-[#0E3A3652]'
              }`}
          >
            {totalRecommendedPolicies}
          </div>
        </div>
        <div className="required-div py-[8px] flex flex-col justify-around">
          <div className="tab-label flex flex-row gap-x-[13px]">
            <TabLabel>Recommended</TabLabel>
            <img className="w-[13px] h-[10pxpx]" src="/images/policies-finder/ic_recommended.svg" alt="logo" />
          </div>
          <div className="tab-description">
            <TabDescription>Nice-to-have but not essential.</TabDescription>
          </div>
        </div>
      </div>
      {recommendedSelected && (
        <div className="hidden sm:block overflow-scroll max-h-[400px]">
          {policiesToShowOnUIRecommended.length > 0 && (
            <div className="px-[33px] bg-white-1">
              <div className="total-policies pb-6 border-b border-b-gray-6">
                <p>
                  <span className="text-base text-green-4">All recommended policies</span>
                  <span className="text-base text-green-4">{` (${totalRecommendedPolicies})`}</span>
                </p>
              </div>
              <PoliciesTableMobile policiesToShowOnUI={policiesToShowOnUIRecommended} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const PoliciesSection: React.FC<{}> = ({ }) => {
  const [currentTab, setCurrentTab] = useState(PolicyTab.REQUIRED)
  const [clickingGo, setClickingGo] = useState(false)
  const [selectedStates, setSelectedStates] = useState(rawStates.filter(state => state.value === "federals")) as any
  const [inputEmployeeCount, setInputEmployeeCount] = useState(1000)
  const [selectedIndustries, setSelectedIndustries] = useState<{ label: string; value: string }[] | never[]>(
    IndustryOptions.filter((industry) => industry.value === 'other'),
  )
  const [currentPolicies, setCurrentPolicies] = useState([]) as any

  const mergeObjects = (dataArray): Policy[] => {
    const uniqueObjects = {}

    dataArray.forEach((obj) => {
      const key = `${obj.label}|${obj.description}`

      // Initialize the unique object if it hasn't been added yet
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = {
          ...obj,
          regions: [obj.region],
        }
        delete uniqueObjects[key].region // We will replace 'region' with 'regions'
      } else {
        uniqueObjects[key].regions.push(obj.region)
      }
    })

    return Object.values(uniqueObjects)
  }

  const policiesToShowOnUI = mergeObjects(currentPolicies)

  const policiesToShowOnUIRequired = policiesToShowOnUI.filter((e) => e.required)
  const policiesToShowOnUIRecommended = policiesToShowOnUI.filter((e) => !e.required)

  const totalRequiredPolicies = policiesToShowOnUIRequired.length + (selectedStates.filter((state: any) => state.value === 'federals').length === 1 ? federalPolicies.length : 0)
  const totalRequiredStateSpecificPolicies = policiesToShowOnUIRequired.length
  const totalRecommendedPolicies = policiesToShowOnUIRecommended.length
  const [showModal, setShowModal] = useState(false)
  const stateRef: any = useClickAway(() => {
    handleLogicStateDropdown()
  })

  const industryRef: any = useClickAway(() => {
    handleLogicIndustryDropdown()
  })

  function handleLogicStateDropdown() {
    let clickEventState = document.querySelector('.multi-select-special-1 .dropdown-heading') as any
    let dropdownContent1IsShow = document.querySelector('.multi-select-special-1 .dropdown-content')
    if (dropdownContent1IsShow) {
      clickEventState.click()
    }
  }

  function handleLogicIndustryDropdown() {
    let clickEventIndustry = document.querySelector('.multi-select-special-2 .dropdown-heading') as any
    let dropdownContent2IsShow = document.querySelector('.multi-select-special-2 .dropdown-content')
    if (dropdownContent2IsShow) {
      clickEventIndustry.click()
    }
  }

  // useEffect(() => {
  //   let clickEventState = document.querySelector('.multi-select-special-1 .dropdown-heading') as any
  //   let dropdownContent1IsShow = document.querySelector('.multi-select-special-1 .dropdown-content')
  //   let clickEventIndustry = document.querySelector('.multi-select-special-2 .dropdown-heading') as any
  //   let dropdownContent2IsShow = document.querySelector('.multi-select-special-2 .dropdown-content')

  //   if (isOutside) {
  //     if (dropdownContent1IsShow) {
  //       clickEventState.click()
  //     }
  //     if (dropdownContent2IsShow) {
  //       clickEventIndustry.click()
  //     }
  //   }
  // }, [isOutside])

  function canPressGoButton() {
    return selectedStates.length > 0 && inputEmployeeCount > 0 && selectedIndustries.length > 0
  }

  function handlePickAllStates() {
    let clonedOptions = JSON.parse(JSON.stringify(selectedStates))
    if (clonedOptions.find((e) => e.value === 'all')) {
      clonedOptions = []
    } else {
      clonedOptions = JSON.parse(JSON.stringify(rawStates))
    }

    return clonedOptions
  }

  function handlePickOneState({ label, value }: { label: string; value: string }) {
    let clonedOptions = JSON.parse(JSON.stringify(selectedStates))
    if (clonedOptions.find((e) => e.value === value)) {
      clonedOptions = clonedOptions.filter((e) => e.value !== value)
    } else {
      clonedOptions.push({ label, value })
    }
    if (clonedOptions.length === rawStates.length - 1 && !clonedOptions.find((e) => e.value === 'all')) {
      clonedOptions.push({ label: 'Select all states', value: 'all' })
    } else {
      clonedOptions = clonedOptions.filter((e) => e.value !== 'all')
    }
    return clonedOptions
  }

  function handlePickAllIndustries() {
    let clonedOptions = JSON.parse(JSON.stringify(selectedIndustries))
    if (clonedOptions.find((e) => e.value === 'all')) {
      clonedOptions = []
    } else {
      clonedOptions = JSON.parse(JSON.stringify(IndustryOptions))
    }
    return clonedOptions
  }

  function handlePickOneIndustry({ label, value }: { label: string; value: string }) {
    let clonedOptions = JSON.parse(JSON.stringify(selectedIndustries))
    if (clonedOptions.find((e) => e.value === value)) {
      clonedOptions = clonedOptions.filter((e) => e.value !== value)
    } else {
      clonedOptions.push({ label, value })
    }
    if (clonedOptions.length === IndustryOptions.length - 1 && !clonedOptions.find((e) => e.value === 'all')) {
      clonedOptions.push({ label: 'Select all states', value: 'all' })
    } else {
      clonedOptions = clonedOptions.filter((e) => e.value !== 'all')
    }
    return clonedOptions
  }

  function handleClickGo() {
    const allPoliciesToShow = policies.filter((policy) =>
      selectedStates.map((state) => state.label).includes(policy.region),
    )
    setCurrentPolicies(allPoliciesToShow)
    setClickingGo(false)
  }

  const DownloadCSV = () => {
    return (
      <div className="download-div flex flex-row items-center">
        <CSVLink
          id="csv-download-link"
          className="font-bold text-sm text-blue-2 cursor-pointer hover:text-blue-2 hover:brightness-75 hidden"
          data={[...federalPolicies, ...currentPolicies]}
          headers={[
            { label: 'Policy Title', key: 'label' },
            // { label: 'Policy Description', key: 'description' },
            { label: 'Region', key: 'region' },
            { label: 'Required?', key: 'required' },
          ]}
          separator={','}
          filename={`your-tacklehr-policies-${new Date().toISOString().substring(0, 10)}.csv`}
        >
          Download .CSV
        </CSVLink>
        <span
          className="font-bold text-sm text-blue-2 cursor-pointer hover:text-blue-2 hover:brightness-75"
          onClick={() => {
            let isAlreadyInputted = sessionStorage.getItem('modal-inputted')
            if (isAlreadyInputted) {
              let ele = document.getElementById('csv-download-link')
              if (ele) {
                ele.click()
              }
            } else {
              handleOpenModal()
            }
          }}
        >
          Download .CSV
        </span>
        <img className="w-[18px] h-[12px] ml-[19px]" src="/images/policies-finder/ic_download.svg" alt="logo" />
      </div>
    )
  }

  function handleOpenModal() {
    let ele = document.querySelector('body')
    if (ele) {
      ele.style.overflowX = 'hidden'
      ele.style.overflowY = 'hidden'
    }
    setShowModal(true)
  }

  function handleCloseModal() {
    let ele = document.querySelector('body')
    if (ele) {
      ele.style.overflowX = 'auto'
      ele.style.overflowY = 'auto'
    }
    setShowModal(false)
  }

  function isValidEmail(email?: string): any {
    if (!email) return false
    // check email format
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi
    // check if domain is common one
    let isBasicDomain = freeBasicEmailDomain.includes(email.split('@')[1] ? email.split('@')[1].split('.')[0] : 'gmail')
    return emailRegex.test(email) && !isBasicDomain
  }

  async function sendMessageToSlack(data: SlackMessageProps) {
    let payload = createSlackMessagePayload(data)
    const baseURL = process.env.GATSBY_SLACK_POLICY_FINDER_WEBHOOK_URL
    if (!baseURL) return
    try {
      await slackAxiosInstance.post(baseURL, JSON.stringify(payload))
    } catch (e) {
      console.log(e)
    }
    let ele = document.getElementById('csv-download-link')
    if (ele) {
      ele.click()
      handleCloseModal()
    }
    sessionStorage.setItem('modal-inputted', 'true')
  }

  function handleLogicFocus(currentTargetId: string, eventType: string): any {
    let mappedDivIds: any = {
      'form-name': 'input-name-div',
      'form-company': 'input-company-div',
      'form-email': 'input-email-div',
    }

    let ele = document.getElementById(mappedDivIds[currentTargetId])
    if (eventType === 'focus') {
      if (ele) {
        ele.classList.remove('border-[#D1D1D1]')
        ele.classList.add('border-[#0E3A36]')
      }
    } else if (eventType === 'blur') {
      if (ele) {
        ele.classList.remove('border-[#0E3A36]')
        ele.classList.add('border-[#D1D1D1]')
      }
    }
    return
  }

  interface SlackMessageProps {
    userName: string
    userCompanyName: string
    userEmail: string
    states: { label: string; value: string }[]
    employeeCount: number
    industries: { label: string; value: string }[]
  }

  function createSlackMessagePayload(data: SlackMessageProps): any {
    let mappedStates = data.states.map((e: any) => `${e.label}`)
    let mappedIndustries = data.industries.map((e: any) => `${e.label}`)
    let payload: any = {
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: 'New message from Tackle HR - Policy Finder',
            emoji: true,
          },
        },
        {
          type: 'rich_text',
          elements: [
            {
              type: 'rich_text_section',
              elements: [
                {
                  type: 'text',
                  text: 'User with the following information has just used the Policy Finder feature: \n',
                },
              ],
            },
            {
              type: 'rich_text_list',
              style: 'bullet',
              elements: [
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Name: ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'text',
                      text: data.userName,
                    },
                  ],
                },
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Company Name: ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'text',
                      text: data.userCompanyName,
                    },
                  ],
                },
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Email: ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'link',
                      url: `${`mailto:${data.userEmail}`}`,
                      text: data.userEmail,
                      style: {
                        bold: true,
                      },
                    },
                  ],
                },
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'State(s): ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'text',
                      text: mappedStates.join(', '),
                    },
                  ],
                },
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Employee count: ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'text',
                      text: `${data.employeeCount}`,
                    },
                  ],
                },
                {
                  type: 'rich_text_section',
                  elements: [
                    {
                      type: 'text',
                      text: 'Industries: ',
                      style: {
                        bold: true,
                      },
                    },
                    {
                      type: 'text',
                      text: mappedIndustries.join(', '),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }
    return payload
  }

  const DownloadCSVModal = ({ show, setShow }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [inputError, setInputError] = useState(false)
    return (
      <div className={show ? 'modal-here block' : 'modal-here hidden'}>
        <div
          onClick={() => handleCloseModal()}
          className={`modal-backdrop-div fixed inset-0 z-[51] bg-[#0000004d] backdrop-blur-[7px]`}
        ></div>
        <div className={`modal-center modal-style z-[52]`}>
          <div className="modal-body-div">
            <div
              className="close-icon-div absolute top-[40px] right-[40px] text-[20px] cursor-pointer"
              onClick={() => handleCloseModal()}
            >
              <img className="" src="/images/policies-finder/ic_close.svg" alt="close-icon" />
            </div>
            <div className="explain-div">
              <p className="font-lato font-bold text-[32px] leading-9 text-green-4 mb-[27px]">
                Enter your details to download
              </p>
              <p className="font-lato font-normal text-[16px] leading-6 mb-[30px]">
                Our complete checklist of reccommended and required policies for your handbook will download
                immediately.
              </p>
            </div>
            <div className="input-div-modal">
              <div
                id={`input-name-div`}
                className="mb-[20px] flex flex-col w-[466px] rounded-[4px] border-[#D1D1D1] border-[0.73px] pt-[10px] pb-[14px] px-[26px]"
              >
                <label className="block font-lato text-[11.72px] leading-[14.65px] text-[#A4B5B3]">Your name</label>
                <input
                  id="form-name"
                  className="block !h-auto !font-lato !text-[16px] !leading-[16.65px] !text-green-4 !p-[2px] !mt-[0px]"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setInputError(false)
                    setName(e.target.value)
                  }}
                  autoComplete="off"
                  onFocus={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                ></input>
              </div>
              <div
                id={`input-company-div`}
                className="mb-[20px] flex flex-col w-[466px] rounded-[4px] border-[#D1D1D1] border-[0.73px] pt-[10px] pb-[14px] px-[26px]"
              >
                <label className="block font-lato text-[11.72px] leading-[14.65px] text-[#A4B5B3]">
                  Your company name
                </label>
                <input
                  id="form-company"
                  className="block !h-auto !font-lato !text-[16px] !leading-[16.65px] !text-green-4 !p-[2px] !mt-[0px]"
                  type="text"
                  value={companyName}
                  onChange={(e) => {
                    setInputError(false)
                    setCompanyName(e.target.value)
                  }}
                  autoComplete="off"
                  onFocus={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                ></input>
              </div>
              <div
                id={`input-email-div`}
                className="flex flex-col w-[466px] rounded-[4px] border-[#D1D1D1] border-[0.73px] pt-[10px] pb-[14px] px-[26px]"
              >
                <label className="block font-lato text-[11.72px] leading-[14.65px] text-[#A4B5B3]">
                  Your company email
                </label>
                <input
                  id="form-email"
                  className="block !h-auto !font-lato !text-[16px] !leading-[16.65px] !text-green-4 !p-[2px] !mt-[0px]"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setInputError(false)
                    setEmail(e.target.value)
                  }}
                  autoComplete="off"
                  onFocus={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                  onBlur={(e) => {
                    handleLogicFocus(e.currentTarget.id, e.type)
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className="modal-footer-div mt-[40px] flex flex-row items-center justify-start gap-x-[31px]">
            <div className="btn-div">
              <button
                className="w-[164px] h-[50px] bg-orange-1 hover:brightness-90 disabled:hover:brightness-100 disabled:bg-opacity-20 rounded-[25px]"
                onClick={() => {
                  if (!isValidEmail || !name || !companyName) {
                    setInputError(true)
                  } else {
                    sendMessageToSlack({
                      userName: name,
                      userEmail: email,
                      userCompanyName: companyName,
                      states: selectedStates,
                      industries: selectedIndustries,
                      employeeCount: inputEmployeeCount,
                    })
                  }
                }
                }
              >
                <p className="font-lato text-[15.39px] leading-[17px] text-white-1">Download</p>
              </button>
            </div>
            <div className="error-div flex flex-col">
              <p
                className={`font-lato text-[16px] leading-[17px] text-red-4 ${inputError ? '' : 'hidden'
                  }`}
              >
                {`Please enter a valid ${name ? '' : 'name, '}${companyName ? '' : 'company name, '}${isValidEmail(email) ? '' : 'email address (custom/company domains only), '
                  }`.slice(0, -2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`policies-section px-8 sm:px-0`} id="policies-section">
      <div className="max-w-[820px] mx-auto relative">
        {/* Filter Div */}
        <div
          className="filter-div max-w-[820px] h-[67px] sm:h-[62px] bg-white-1 rounded-[73px] sm:rounded-none -translate-y-1/2 sm:translate-y-0 flex flex-row justify-between"
          style={{ boxShadow: '0px 1px 3px rgba(0,0,0, 0.06)' }}
        >
          <div className="state-filter sm:min-w-[140px] sm:w-[250px] w-[231px] mx-auto pt-[14px]">
            <FilterLabel className="pl-[36px] sm:pl-[27px] pr-5 sm:pr-4">{`State(s)`}</FilterLabel>
            <div ref={stateRef}>
              <MultiSelect
                className="multi-select-special-1 outline-none"
                options={rawStates}
                value={selectedStates}
                hasSelectAll={false}
                labelledBy="states"
                ClearIcon={<div></div>}
                ClearSelectedIcon={null}
                valueRenderer={(selected, _options) => {
                  return selected.length === 0 ? (
                    <p className="font-lato text-[15px] text-green-4 pl-[36px] sm:pl-[27px] pr-20 sm:pr-4">Select</p>
                  ) : selected.length === 1 ? (
                    <p className="font-lato text-[15px] text-green-4 pl-[36px] sm:pl-[27px] pr-5 sm:pr-4">
                      {selected[0].label}
                    </p>
                  ) : (
                    <p className="font-lato text-[15px] text-green-4 pl-[36px] sm:pl-[27px] pr-5 sm:pr-4">{`${selected.findIndex((e) => e.value === 'all') === -1 ? selected.length : selected.length - 1
                      } states`}</p>
                  )
                }}
                ItemRenderer={({ checked, option, onClick, disabled }) => {
                  return (
                    <div className={`item-renderer flex flex-row !items-center ${disabled ? 'disabled' : ''}`}>
                      {!option.isGroupText ? (
                        <>
                          <input
                            id={option.value}
                            className="border-outline-none w-[18px] h-[18px] block !mr-[17px] rounded-[4px]"
                            type="checkbox"
                            onChange={() => {
                              if (option.isPickAll) {
                                setSelectedStates(handlePickAllStates())
                              } else {
                                setSelectedStates(handlePickOneState(option))
                              }
                              setCurrentPolicies([])
                              setClickingGo(true)
                            }}
                            checked={checked}
                            tabIndex={-1}
                            disabled={disabled}
                          />
                          <p className={`font-lato text-[14px] text-green-4 ${checked ? 'font-bold' : 'font-normal'}`}>
                            {option.label}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="!cursor-context-menu font-lato text-[12px] text-green-4">{option.label}</p>
                        </>
                      )}
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className="employee-count-filter w-[231px] sm:w-[106px] border-l-1 border-[#979797] pl-7 sm:pl-4 border-opacity-30 mx-auto pt-[14px]">
            <FilterLabel className="sm:hidden">{`Employee Count`}</FilterLabel>
            <FilterLabel className="hidden sm:block pb-1">{`# of Employees`}</FilterLabel>
            <input
              id="employeeCount"
              className="outline-none font-lato text-[15.39px] leading-[14.65px] text-[#0E3A36] caret-[#FB8B42] input-number-hide-arrow h-[24px]"
              placeholder="eg.100"
              type="number"
              value={inputEmployeeCount === 0 ? '' : inputEmployeeCount}
              onChange={(e) => {
                setInputEmployeeCount(parseInt(e.target.value))
                setCurrentPolicies([])
                setClickingGo(true)
              }}
            ></input>
          </div>
          <div className="industry-filter w-[231px] sm:min-w-[140px] sm:w-[250px] border-l-1 border-[#979797] border-opacity-30 mx-auto pt-[14px] sm:pr-[27px]">
            <FilterLabel className="pl-7 sm:pl-4">{`Industry`}</FilterLabel>
            <div ref={industryRef}>
              <MultiSelect
                className="multi-select-special-2 outline-none"
                options={IndustryOptions}
                value={selectedIndustries}
                hasSelectAll={false}
                labelledBy="industry"
                ClearIcon={<div></div>}
                ClearSelectedIcon={null}
                valueRenderer={(selected, _options) => {
                  return selected.length === 0 ? (
                    <p className="font-lato text-[15px] text-green-4 pl-7 sm:pl-4">Select</p>
                  ) : selected.length === 1 ? (
                    <p className="font-lato text-[15px] text-green-4 pl-7 sm:pl-4">{selected[0].label}</p>
                  ) : (
                    <p className="font-lato text-[15px] text-green-4 pl-7 sm:pl-4">{`${selected.findIndex((e) => e.value === 'all') === -1 ? selected.length : selected.length - 1
                      } industries`}</p>
                  )
                }}
                ItemRenderer={({ checked, option, onClick, disabled }) => {
                  return (
                    <div className={`item-renderer flex flex-row !items-center ${disabled ? 'disabled' : ''}`}>
                      {!option.isGroupText ? (
                        <>
                          <input
                            id={option.value}
                            className="border-outline-none w-[18px] h-[18px] block !mr-[17px] rounded-[4px]"
                            type="checkbox"
                            onChange={() => {
                              if (option.isPickAll) {
                                setSelectedIndustries(handlePickAllIndustries())
                              } else {
                                setSelectedIndustries(handlePickOneIndustry(option))
                              }
                              setCurrentPolicies([])
                              setClickingGo(true)
                            }}
                            checked={checked}
                            tabIndex={-1}
                            disabled={disabled}
                          />
                          <p className={`font-lato text-[14px] text-green-4 ${checked ? 'font-bold' : 'font-normal'}`}>
                            {option.label}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="!cursor-context-menu font-lato text-[12px] text-green-4">{option.label}</p>
                        </>
                      )}
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className="sm:hidden go-btn-div flex flex-col justify-center relative -left-[3px]">
            <button
              onClick={handleClickGo}
              disabled={!canPressGoButton()}
              className="w-[51px] h-[51px] bg-orange-1 rounded-full text-base text-white-1 disabled:bg-orange-2"
            >
              Go
            </button>
          </div>
        </div>
        <button
          onClick={handleClickGo}
          disabled={!canPressGoButton()}
          className="hidden w-full go-btn-div sm:block h-[62px] bg-orange-1 text-white-1 disabled:bg-orange-2"
        >
          <p className={`max-w-[262px] mx-auto ${canPressGoButton() ? 'text-[18px]' : 'text-[14px]'}`}>
            {canPressGoButton() ? 'Go' : 'Enter your details above to see the policies that apply to your company.'}
          </p>
        </button>
        {/* Policies Table Web */}
        <div className="policies-table-web max-w-[820px] my-6 sm:my-0 border-[#DFDFDF] border-[0.71px] rounded-md bg-[#DFDFDF]">
          {/* Tab Section */}
          <PolicyTabs
            selectedStates={selectedStates}
            clickingGo={clickingGo}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            totalRequiredPolicies={currentPolicies.length > 0 ? totalRequiredPolicies : federalPolicies.length}
            totalRecommendedPolicies={totalRecommendedPolicies}
            policiesToShowOnUIRequired={policiesToShowOnUIRequired}
            policiesToShowOnUIRecommended={policiesToShowOnUIRecommended}
            federalPoliciesMerged={mergeObjects(federalPolicies)}
          />
          <DownloadCSVModal show={showModal} setShow={setShowModal}></DownloadCSVModal>

          {/* Table Section */}
          <div
            className={`sm:hidden table-section px-[22px] rounded-b-md bg-white-1 py-4 ${currentTab === PolicyTab.REQUIRED ? 'rounded-tr-md' : 'rounded-tl-md'
              }`}
          >
            {/* Summary Div */}

            {currentTab === PolicyTab.RECOMMENDED && (
              <>
                <div className="summary-div flex flex-row justify-between">
                  {policiesToShowOnUIRecommended.length > 0 ? (
                    <div className="total-policies">
                      <p>
                        <span className="text-base text-green-4">All recommended policies</span>
                        <span className="text-base text-green-4">{` (${totalRecommendedPolicies})`}</span>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {currentPolicies.length > 0 && <DownloadCSV />}
                </div>
                <PoliciesTableDesktop policiesToShowOnUI={policiesToShowOnUIRecommended} />
                {policiesToShowOnUIRecommended.length === 0 && (
                  <div className="font-lato text-sm text-center w-[298px] m-auto pt-[44px] pb-[64px]">
                    <p className="text-[#0e3a365e]">
                      Enter your details above to see the policies that apply to your company.
                    </p>
                  </div>
                )}
              </>
            )}

            {currentTab === PolicyTab.REQUIRED && (
              <>
                <div>
                  <div className="summary-div flex flex-row justify-between">
                    {((selectedStates.filter(state => state.value === 'federals').length > 0 && clickingGo === false)) ? (
                      <div className="total-policies">
                        <p>
                          <span className="text-base text-green-4">Federal Policies</span>
                          <span className="text-base text-green-4">{` (${federalPolicies.length})`}</span>
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                    {currentPolicies.length > 0 && <DownloadCSV />}
                  </div>
                  {((selectedStates.filter(state => state.value === 'federals').length > 0 && clickingGo === false)) && (
                    <PoliciesTableDesktop policiesToShowOnUI={mergeObjects(federalPolicies)} />
                  )}
                </div>
                <div>
                  <div className="summary-div flex flex-row justify-between">
                    {policiesToShowOnUIRequired.length > 0 ? (
                      <div className="total-policies pt-6">
                        <p>
                          <span className="text-base text-green-4">State specific policies</span>
                          <span className="text-base text-green-4">{` (${totalRequiredStateSpecificPolicies})`}</span>
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <PoliciesTableDesktop policiesToShowOnUI={policiesToShowOnUIRequired} />
                </div>
                {(policiesToShowOnUIRequired.length === 0 && clickingGo === true) && (
                  <div className="font-lato text-sm text-center w-[298px] m-auto pt-[44px] pb-[64px]">
                    <p className="text-[#0e3a365e]">
                      Enter your details above to see the policies that apply to your company.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const FilterLabel = tw.p`text-[11.72px] leading-[14.65px] text-green-5`
const TabLabel = tw.p`text-[21px] text-green-4`
const TabDescription = tw.p`text-[12px] leading-[14px] text-gray-5`

export default PoliciesSection
