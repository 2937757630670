import React from 'react'

interface AboutProps {}

const About: React.FC<AboutProps> = ({}) => {
  return (
    <div className={`pt-[155px] sm:pt-[110px] px-[27px] bg-[#E4EEEC]`} id="about">
      <div className="max-w-[820px] mx-auto">
        <div className="flex-1 flex flex-col text-green-4">
          <h1 className="max-w-[586px] sm:max-w-[326px] font-bold text-[52px] sm:text-[30px] leading-[62px] sm:leading-[36px]">
            Policies for your Employee Handbooks
          </h1>
          <p className="sm:max-w-[255px] text-green-4 sm:text-[14px] leading-6 sm:leading-[21px] pt-[17px] pb-[82px] sm:pb-6">
            Enter your company details below to see
            <span className="font-bold text-green-4 sm:text-[14px] leading-6 sm:leading-[21px]">
              {' '}
              Required & Recommended policies
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
