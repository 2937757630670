import React from 'react'

const BookConsultationBanner: React.FC<{}> = ({ }) => {
  return (
    <div className="bg-white-1 pb-5 z-[999] h-20 fixed bottom-0 w-screen lg:bg-green-3 lg:pb-3 lg:h-auto">
      <div className="max-w-[831px] mx-auto relative sm:max-w-full sm1:lg:max-w-[600px] sm:ml-4 sm:mr-8">
        <img
          className="absolute right-[-40px] top-3 z-[-1] w-[60px] h-[43px] lg:hidden"
          src="/images/home/ic_leaf_5.svg"
          alt="leaf"
        />
        <img
          className="absolute hidden right-[-24px] top-4 z-[-1] lg:w-[47px] lg:h-[46px] lg:right-[-28px] lg:block lg:top-1"
          src="/images/home/ic_leaf_8.svg"
          alt="leaf"
        />

        <div className="flex items-center pt-6 lg:pt-4">
          <img
            className="w-[40px] h-[40px] rounded-[57px] shadow-3 lg:w-10 lg:h-10 lg:shadow-4 lg:rounded-[40px]"
            src="/images/home/ic_avatar.webp"
            alt="avatar"
          />
          {/* <span className="text-blue-1 text-17/16 ml-5 lg:text-13/16 lg:ml-4 xs1:w-[142px] lg:leading-18/16">
            Get in touch to arrange a free consultation call!
          </span> */}
          <span className="text-blue-1 text-17/16 ml-5 lg:text-13/16 lg:ml-[14px] xs1:w-[142px] lg:leading-18/16">
            Book a consultation to have a walkthrough of all the policies!
          </span>
          <a
            className="w-[106px] h-8 block ml-auto mr-0 pt-2.5 bg-blue-1 text-white-1 text-10/16 font-bold text-center tracking-widest hover:text-white-1 hover:bg-blue-1/95 lg:w-[91px] lg:h-[27px] lg:text-8/16 lg:pt-2 lg:self-center"
            href="https://calendly.com/taylor-airmason/free-consultation"
            target="_blank"
          >
            BOOK NOW
          </a>
        </div>
      </div>
    </div>
  )
}

export default BookConsultationBanner
